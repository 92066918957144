require("axios");
require("vue");

import { createApp } from 'vue';
import axios from 'axios';

// js call method
function top_cal_api_process(tgt_dom_id, select_base_url, wday_base){

    const Cal = {
        data() {
            return {
                info: {},
                loading: true,
                errored: false,
            }
        },
        methods: {
            call_api: function(time){
                var tgt_url = select_base_url + '?wday_base=' + wday_base;
                if(time != null){
                    tgt_url = tgt_url + '&time=' + time;
                }

                var vueObj = this;
                axios
                    .get(tgt_url)
                    .then(response => {
                        vueObj.info = response.data;
                        // console.log('loaded');
                    })
                    .catch(error => {
                        console.log(error)
                        vueObj.errored = true;
                    })
                    .finally(() => vueObj.loading = false);
            }
        },
        computed: {
        },
        mounted: function(){
            var vueObj = this;
            vueObj.call_api(null);
        }
    };
    const app = createApp(Cal).mount(tgt_dom_id);
}

// ここが有効になればグローバルに使えるようになる。
window.top_cal_api_process = top_cal_api_process;
